/* 
===============================
This styles is basically for 
pages that have common styles
==============================
 */
.general-banner {
  position: relative;
}
.general-banner::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  content: "";
  height: 100%;
  background: linear-gradient(180deg, rgba(11, 18, 21, 0) 0%, #0b1215 100%);
}
.page-section {
  background: var(--black-color);
  color: var(--white-color);
}
.page-section input,
.page-section textarea {
  background: none;
  border-bottom: 1px solid #cac4d040;
  border-radius: 0;
  color: #fff;
}
.page-section label {
  color: #cac4d0bf;
  text-transform: uppercase;
  font-weight: 400;
}
.page-section button[type="submit"] {
  text-align: center;
  font-weight: 600;
  font-size: 0.875rem;
  width: 100%;
  background: #125da6;
  border-radius: 8px;
  color: #fff;
}
.page-section p,
.page-section h2 {
  color: #efece6;
}
.page-section p {
  font-weight: 400;
  line-height: 26px;
}
.page-section input[type="date"] {
  position: relative;
}
.page-section input[type="date"]::before {
  position: absolute;
  top: 50%;
  right: 10px;
  width: auto;
  display: inline-block;
  transform: translate(0%, -50%);
  content: url(../../public/assets/icons/book-event-icon.svg);
  z-index: 1010;
  cursor: pointer;
}
.page-section input[type="date"]::-webkit-calendar-picker-indicator {
  background: #fff;
  border: 1px solid red;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1030;
}
/* Background images for different bgs */
.event-page .general-banner {
  background: url(../../public/assets/services/holiday_party.jpg) no-repeat top
    left/cover;
}
.contactus-page .general-banner {
  background: url(../../public/assets/contactus/contactus-banner.svg) no-repeat
    top left/cover;
}
.studio-page .general-banner {
  background: url(../../public/assets/services/studio_pix.jpg)
    no-repeat top left/cover;
}

.general-banner .banner-container {
  display: flex;
  min-height: 95vh;
  position: relative;
}
.general-banner .banner-wrapper {
  /* flex-basis: 72%; */
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
  text-align: center;
}
.general-banner .banner-wrapper h1 {
  color: #fff;
}
