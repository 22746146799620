/* ====================================
Default Class Styles across all pages
======================================= */
/*** Import Google Fonts **/
@import url("https://fonts.googleapis.com/css2?family=Antonio:wght@100..700&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  --primary-btn: #346bd4;
  --btn-border: 1px solid #79747e;
  --dark-color: #0b1215;
  --frame-color: #efece6;
  --black-color: #0b1215;
  --white-color: #efece6;
  --white-bg: #efece6;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  overflow-x: hidden;
}
*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}
*::selection {
  background: #3a8d97;
  color: #fff;
}
::placeholder {
  font-size: 1rem;
}
a {
  text-decoration: none;
  display: block;
  padding: 0.7rem;
  text-transform: capitalize;
  font-size: 0.878rem;
  font-weight: 600;
  color: var(--black-color);
}
body {
  padding: 0;
  margin: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
  background: var(--white-bg);
  color: var(--black-color);
  counter-reset: count;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 0.85rem;
  color: var(--black-color);
  font-family: "Antonio", sans-serif;
}
h2,
h3,
h4,
h5,
h6 {
  line-height: 100%;
}
h1 {
  line-height: initial;
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.5rem;
  font-weight: 300;
}
h5 {
  font-size: 0.75rem;
  font-weight: 600;
}

input,
button {
  box-shadow: none;
  border: none;
  outline: none;
}
img {
  display: block;
  border-radius: inherit;
  width: 100%;
}
.img-container {
  /* display: inline-block;
  margin: 0 auto; */
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
p {
  color: var(--black-color);
  font-weight: 600;
  font-size: 0.875rem;
  font-family: "Plus Jakarta Sans", sans-serif;
}
section {
  padding: 1.5rem 5%;
}

.cta-btn,
button:not(.toastify-btn) {
  padding: 0.75rem 1.2rem;
  display: inline-block;
  text-transform: capitalize;
  cursor: pointer;
  font-family: "Plus Jakarta Sans", sans-serif;
}
.cta-btn {
  text-align: center;
  background: var(--primary-btn);
}
.secondary-btn {
  background: var(--dk-brown);
}
/* ============Form element Stykes========= */
.form-container .double > * {
  margin-bottom: 1.5rem;
}

.form-field {
  position: relative;
  border-radius: 5px;
  margin-bottom: 1.5rem;
}
.form-field label {
  text-transform: capitalize;
  margin-bottom: 0.45rem;
  font-weight: 600;
  display: block;
  font-size: 0.875rem;
}
.form-field input,
.form-field textarea {
  width: 100%;
  padding: 0.7rem;
  border-radius: 8px;
  border: none;
  box-shadow: none;
  outline: none;
}
input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
}
.form-field .eye-icon-container,
.form-field .icon-container {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  right: 15px;
  cursor: pointer;
  display: inline-block;
  width: 25px;
  height: 25px;
}

/* ==============Message info============ */
.form-container .error-message {
  color: #f00;
  font-size: 0.875rem;
}
/*============ Flex and Grid Items================ */
.grid-container-2 > *,
.grid-container-3 > *,
.flex-container-2 > *,
.flex-container-4 > * {
  margin-bottom: 1.5rem;
}

/* ==========================
For Desktop Device
=============================*/
@media (min-width: 980px) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1rem;
  }
  h5 {
    font-size: 0.85rem;
  }
  a,
  p {
    font-size: 1rem;
  }
  section {
    padding: 2rem 7%;
  }
  /* ============Form element Stykes========= */
  .form-container .double {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 4%;
  }
  .form-container .double > * {
    flex-basis: 48%;
  }

  .flex-container-4,
  .flex-container-2 {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 4%;
  }
  .flex-container-4 {
    align-items: flex-start;
  }
  .flex-container-2 > * {
    flex-basis: 48%;
  }
  .flex-container-4 > * {
    flex-basis: 22%;
  }
  .grid-container-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.4rem;
  }
  .grid-container-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* place-items: center; */
  }
  .flex-container-2 > *,
  .flex-container-4 > *,
  .grid-container-3 > *,
  .grid-container-2 > * {
    margin-bottom: 0;
  }
}

/* ==========================
Large Desktop Device
==============================*/
@media (min-width: 1200px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 3.5rem;
  }
  h3 {
    font-size: 2rem;
  }
  h5 {
    font-size: 1rem;
  }
  .form-field label {
    font-size: 1rem;
  }
}
