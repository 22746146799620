/* Home styles goes Here */

/* -------home page---------- */
.home-page {
  /* border: 1px solid red;
  position: relative;
  z-index: -2; */
}
.home-page > * {
  /* margin-bottom: 5rem; */
}
.home-page > *:last-child {
  margin-bottom: 0;
}

.home-page .inner-flex {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}
.home-page .inner-flex > * {
  /* border: 1px solid red; */
}
/* ----------Hero section-------- */
.hero-section {
  background: #0b1215;
  border-radius: 0 0 50px 50px;
  padding-bottom: 100px;
}
.hero-section h1,
.hero-section h2,
.hero-section h3,
.hero-section h5 {
  color: #ffffff;
}
.home-page h2,
.hero-section h2 {
  font-weight: 400;
  margin-bottom: 1.5rem;
  font-size: 2rem;
}
.hero-section h5 {
  font-weight: 600;
  font-family: "Plus Jakarta Sans", sans-serif;
  line-height: 24px;
}
.hero-btn .btn,
.home-page .btn {
  border-radius: 8px;
  font-weight: 400;
  font-size: 0.875rem;
  display: block;
  color: var(--white-color);
  background: var(--black-color);
  border: 1px solid #79747e;
  padding: 14px 24px 14px 16px;
  /* margin-top: 0.85rem; */
  text-transform: initial;
  width: 100%;
  width: 207px;
}
.home-page a .btn {
  padding: auto;
}
.hero-btn .btn.active,
.hero-section .btn.active {
  background: linear-gradient(0deg, #1465b4, #1465b4),
    linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08));
  color: #fff;
  border: none;
}
.hero-section .inner-flex {
  margin-top: 1.5rem;
  flex-flow: column wrap;
  align-items: center;
}
.hero-section .inner-flex > :first-child span,
.hero-section .inner-flex > :last-child span,
.hero-btn .btn span,
.photo-studio .btn span {
  position: relative;
}
.hero-section .inner-flex > :last-child span::before,
.hero-section .inner-flex > :first-child span::before,
.photo-studio .btn span::before,
.hero-btn .btn span::before {
  position: absolute;
  top: 0;
  left: -25px;
  display: inline-block;
  content: url(../../public/assets/icons/book-event-icon.svg);
}
.hero-section .inner-flex > :last-child span::before,
.photo-studio .btn span::before {
  content: url(../../public/assets/icons/photo-studio-icon.svg);
}
.hero-section > .flex-container-2 {
  margin-top: -80px;
  position: relative;
  z-index: 1020;
  padding: 10px 8px 20px 10px;
}
.hero-section > .flex-container-2 > :first-child {
  flex-basis: 66%;
}
.hero-section > .flex-container-2 > :last-child {
  flex-basis: 30%;
}

/* ===============Banner section styles============== */
.banner-section .img-container {
  height:520px;
  overflow:hidden;
}
.banner-section .img-container img{
  object-fit:cover;
  height:100%;
}

/* ===============About us styles============== */
.aboutus-section {
  padding: 89px 10px 10px 10px;
}

.aboutus-section p {
  font-size: 0.75rem;
  line-height: 20px;
}
.aboutus-section p,
.aboutus-section h2 {
  color: var(--white-color);
  text-align: center;
}
.aboutus-section .flex-container-2 {
  align-items: flex-start;
  border-radius: 23px;
  gap: 0;
  /* height: 591px; */
  position: relative;
  z-index: 998;
  display: flex;
  flex-flow: column wrap;
}
.aboutus-section .flex-container-2 > * {
  flex-basis: 50%;
}
.aboutus-section .flex-container-2 > * {
  /* border-radius: inherit; */
}

.aboutus-section .flex-container-2 > :last-child {
  order: 1;
  border-radius: 23px 23px 0 0;
  overflow: hidden;
}
.aboutus-section .flex-container-2 > :first-child {
  padding: 2rem;
  border-radius: 23px 0 0 23px;
  height: auto;
  order: 2;
}
.aboutus-section .flex-container-2 > :first-child::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 23px;
  background: #cd663d;
  z-index: -1;
}

/* .aboutus-section .flex-container-2 > :first-child {
  border: 1px solid green;
} */

/* ===========================
   Our Services
==============================*/
.our-services {
  background: #fff;
}

.our-services button {
  background: none;
  font-weight: 600;
  padding: 8px 16px;
}
.our-services button.active {
  color: #f69b28;
}
.our-services .flex-container-2 {
  align-items: flex-start;
  justify-content: space-between;
}

/* =======================
   Amenities
=========================*/
.amenities {
  box-shadow: 0px 4px 12px 0px #77777726 inset;
  background: #eceff0;
  border-radius: 0px 0px 23px 23px;
  padding-bottom: 100px;
  margin-bottom: 0;
}

.amenities h3 {
  text-align: center;
}
/* .amenities h5 {
  border: 1px solid blue;
} */
.amenities .icon-container {
  display: inline-block;
}
.amenities .grid-container-3 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
}

/* ==========Our Gallery======================= */
/* .our-gallery{

} */

/* =======================
Our Gallery
========================= */
.our-gallery {
  background: var(--black-color);
  margin-bottom: 0;
}
.our-gallery h2 {
  color: #efece6;
}

/* =======================
Tour
========================= */
.tour-section {
  background: var(--black-color);
  padding: 94px 10px 48px 10px;
}
.tour-section h2 {
  color: #efece6;
}
.tour-section .form-container {
  background: #f8f7f4;
  padding: 1rem 21px;
  border-radius: 8px;
}
.tour-section .form-container label {
  font-weight: 600;
}
.tour-section .form-container input,
.tour-section .form-container textarea {
  color: var(--black-color);
}
.tour-section .form-container textarea {
  border: 1px solid #79747e;
}
.tour-section .form-container button[type="submit"] {
  background: #1465b4;
  color: #fff;
  border-radius: 8px;
  font-weight: 600;
}
.tour-section .form-container input[type="date"] {
  position: relative;
  display: block;
}
.tour-section .form-container .pick-date {
  position: absolute;
  top: 0;
  top: 65%;
  right: 0;
  transform: translate(0%, -50%);
  left: 0;
  z-index: 998;
  text-align: right;
  cursor: pointer;
  font-weight: 600;
}
.tour-section
  .form-container
  input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1030;
}
.tour-section .form-container input[type="date"]::-webkit-inner-spin-button {
  opacity: 0;
}
/* =======================
Our Photo Studio
========================= */
.photo-studio {
  background: url(../../public/assets/images/homepg-studio-bg.svg) no-repeat top
  /* background: url(../../public/assets/services/studio_pix.jpg) no-repeat top */
    left/cover;
  margin-bottom: 0;
}
.photo-studio button.btn {
  background: linear-gradient(0deg, #1465b4, #1465b4),
    linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08));
  border: none;
  margin-top: 1.5rem;
}
.photo-studio p {
  color: #efece6;
  font-style: normal;
  line-height: 26px;
  font-weight: 500;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 0.875rem;
}
.photo-studio h2 {
  color: #efece6;
}

/* ==============================
 Tablet Device
================================*/
@media (min-width: 710px) {
  .hero-section .inner-flex {
    align-items: flex-start;
    flex-flow: row wrap;
  }
  .hero-section .inner-flex > * {
    flex-basis: auto;
  }
}

/* ==============================
 Desktop Device
================================*/
@media (min-width: 980px) {
  .hero-section h5 {
    text-align: right;
  }
  .hero-section > .flex-container-2 {
    margin-top: -100px;
  }
  .hero-section button {
    font-size: 1rem;
  }
  .banner-section {
    height: 489px;
    overflow: hidden;
  }

  .aboutus-section .flex-container-2 {
    flex-flow: row wrap;
  }
  .aboutus-section .flex-container-2 > :last-child {
    border-radius: 0 23px 23px 0;
  }
  .aboutus-section .flex-container-2 > :first-child {
    border-radius: 23px 0 0 23px;
    order: 1;
  }

  .event-and-booking h3 {
    text-align: center;
  }
  .amenities .grid-container-3 {
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
  }
  .amenities .grid-container-3 > * {
    margin-bottom: 2.8rem;
  }
  .event-and-booking h2 {
    text-align: initial;
  }

  .our-gallery h2 {
    font-size: 5rem;
    text-align: center;
  }
  .tour-section h2 {
    text-align: center;
  }
  .tour-section .form-wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-around;
    gap: 2%;
  }
  .tour-section .form-wrapper .form-field {
    margin-bottom: 0;
  }
  .tour-section .tour-container {
    display: flex;
  }
  .tour-section .form-container {
    margin: auto;
    flex-basis: 85%;
  }
  .tour-section .form-wrapper > * {
    flex-basis: 18%;
  }
  /* .tour-section .form-wrapper > :nth-child(4){
  
  } */
  .tour-section .form-container input {
    padding: 0.7rem 0.2rem;
  }
  .tour-section .form-container button[type="submit"] {
    margin-top: 0.5rem;
  }
  .tour-section .form-container .pick-date {
    font-size: 0.875rem;
  }
  /* .tour-section
    .form-container
    input[type="date"]::-webkit-calendar-picker-indicator {

  } */
}

/* 
============================
Large Desktop
============================*/
@media (min-width: 1200px) {
  .hero-section > .flex-container-2 {
    margin-top: -120px;
  }
  .aboutus-section p {
    font-size: 1.25rem;
    line-height: 36px;
  }
  .our-services button {
    font-size: 1.35rem;
  }
  .photo-studio p {
    font-size: 1.25rem;
  }
  .our-gallery h2 {
    font-size: 6rem;
  }
}
