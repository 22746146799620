/* =============Header Styles goes Here========== */
.header-container {
  /* border: 1px solid red; */
  /* padding: 1rem 1.8rem; */
  position: relative;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.125);
  /* position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 0.85rem 1.5rem;
  border: 1px solid red;
  z-index: 1020; */
}
.header-container .togglebar,
.header-container .close-icon {
  display: inline-block;
  cursor: pointer;
  padding: 4px 14px;
  /* border: 1px solid #1f2029;
  border-radius: 8px; */
  /* position: relative;
  z-index:1020; */
  vertical-align: middle;
}
.header-container .togglebar {
  display: flex;
  align-items: center;
}
.header-container .close-icon {
  position: absolute;
  top: 35px;
  right: 25px;
  cursor: pointer;
  font-size: 1.8rem;
  vertical-align: middle;
  color: var(--black-color);
}
.header-container .logo-container {
  width: 50px;
  /* height: 58px; */
  padding: 0;
  display: inline-block;
}

/* ===============Navbar Styles================== */
.navbar-container > .flex-container-2 {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: 10px 20px 10px 1rem;
  background: #ffffff;
  z-index: 1040;
}
.navbar-container > .flex-container-2 > * {
  margin-bottom: 0;
  flex-basis: auto;
}
.navbar-container > .flex-container-2 > :last-child {
  /* border: 2px solid blue; */
}
.nav-menu {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: -1;
}
.nav-menu.active {
  background: rgb(128 128 128 / 40%);
  z-index: 1040;
  position: fixed;
}
.nav-menu.active .menu-container {
  transform: translateX(0%);
  transition: 0.5s ease;
}
/* ===============Menu Styles================== */

.menu-container {
  background: var(--white-color);
  position: absolute;
  top: 0;
  right: 0;
  /* width: 260px; */
  width: 100%;
  height: 100vh;
  overflow: auto;
  transform: translateX(150%);
  padding: 1rem;
  display: flex;
}
.menu-container::-webkit-scrollbar {
  height: 5px;
  width: 4px;
  background: #f8f8f8;
}
.menu-container::-webkit-scrollbar-thumb {
  background: var(--purple3);
  border-radius: 2px;
}
.list-menu {
  margin: 75px auto;
  /* width: 100%; */
}
.menu-container a {
  color: var(--purple);
}
.menu-container ul li:last-child {
  padding-bottom: 2rem;
}

@media (min-width: 980px) {
  /* =============Header Styles goes Here========== */

  /* ===============Navbar Styles================== */
  .navbar-container > .flex-container-2 {
    background: #0b121529;
  }

  /* ===============Menu Styles================== */
  .menu-container {
    width: 260px;
  }
  .header-container .togglebar {
    color: #fff;
  }
  .header-container .togglebar svg path {
    stroke: #fff;
  }
}
